

import { Button } from '@/Components/ui/button';
import { Label } from '@/Components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from "@/Components/ui/popover";
import { RadioGroup, RadioGroupItem } from '@/Components/ui/radio-group';
import { Separator } from '@/Components/ui/separator';
import { navigate, ucwords } from '@/Hooks/Functions';
import { ConfirmDialog } from "@/lib/mixins";
import { cn } from '@/lib/utils';
import { usePromptBuilder } from '@/Pages/HomeV2/store';
import { Icon } from '@iconify/react';
import { usePage } from '@inertiajs/react';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

const fetchAccounts = async () => {
    const { data } = await window.axios.get(route('integration.accounts'));
    return data;
}

export default function AiAccountSelector({ dense = false, className = "" }) {
    const account = usePromptBuilder(state => state.account);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const setPromptBuilder = usePromptBuilder(state => state.set);
    const onAccountChange = useCallback((v) => setPromptBuilder('account', v), [setPromptBuilder]);

    const { data: accounts } = useQuery({
        queryKey: ['ai-accounts'],
        queryFn: () => fetchAccounts()
    })

    const selectedAccount = useMemo(() => accounts?.find(a => a.id === account) || accounts?.[0], [accounts, account]);

    const { data: generativeCredits } = useQuery({
        queryKey: ['ai-credits', account],
        queryFn: () => window.axios.get(route('integration.account-credits', { id: account })).then(({ data }) => data?.api_credits || 0),
        enabled: !!account
    })

    const label = useMemo(() => {
        if (dense) return selectedAccount?.display_dense_name || "Select Account"
        return selectedAccount?.display_name || "Select Account To Use"
    }, [selectedAccount, dense])

    const onConnectAccount = useCallback((type) => {
        ConfirmDialog.fire({
            title: `Connect Your Own ${ucwords(type)} Account`,
            text: "You're about to be directed to the configuration page. Do you want to proceed?",
            confirmButtonText: 'Yes Proceed.',
            allowOutsideClick: false
        }).then((result) => {
            result.isConfirmed && navigate(route('dashboard'));
        })
    }, [])

    return <div className={cn('flex flex-row justify-center border rounded-full divide-x-2  flex-nowrap  items-stretch', className)}>
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <div onClick={() => setOpen(true)} className='px-4 py-1 bg-primary text-white cursor-pointer flex-1 inline-flex gap-x-2 flex-nowrap items-center rounded-l-full'>
                    <Icon icon="mdi:account-cog" className='text-lg' />
                    <span className='text-xs inline-flex font-semibold text-center truncate'>{label}</span>
                </div>
            </PopoverTrigger>
            <PopoverContent className="w-80 flex flex-col py-4 relative rounded-2xl gap-4">
                <Icon icon="mdi:close" onClick={() => setOpen(false)} className='text-2xl absolute top-2 right-2 hover:text-cyan-600 cursor-pointer' />
                <RadioGroup value={account} onValueChange={v => onAccountChange(v)} className="flex flex-col py-4 gap-4">
                    {
                        accounts?.map((acc) => <div key={acc.id} className="flex items-center gap-3">
                            <RadioGroupItem value={acc.id} id={`option-${acc.id}`} />
                            <Label htmlFor={`option-${acc.id}`} className="hover:underline cursor-pointer">{acc.display_name}</Label>
                        </div>)
                    }
                </RadioGroup>
                <Separator />
                <Button onClick={() => onConnectAccount('leonardo.ai')} variant="theme-outline" size="xs" className="block">Connect Your Leonardo.Ai Account</Button>
            </PopoverContent>
        </Popover>
        <Popover open={open2} onOpenChange={setOpen2}>
            <PopoverTrigger asChild>
                <div onClick={() => setOpen2(true)} data-tooltip-id="rmp-tooltip" data-tooltip-class-name='max-w-[400px]' data-tooltip-content="If using system account, this refers to the number of credits, where each credits is equivalent to to 1 image generation. If using your own account this refer to the number of API tokens/credits your account has."
                    className='px-4 py-1 bg-gray-200 cursor-pointer text-center  inline-flex gap-x-2 flex-nowrap items-center rounded-r-full'>
                    <Icon icon="healthicons:coins" className='text-lg' />
                    <span className='text-sm font-semibold'>{generativeCredits || 0}</span>
                    <span className='text-xs font-semibold truncate'>{!dense && <span>Generative</span>} Credits</span>
                </div>
            </PopoverTrigger>
            <PopoverContent className="w-80 flex rounded-2xl flex-col relative gap-4">
                <Icon icon="mdi:close" onClick={() => setOpen2(false)} className='text-2xl absolute top-2 right-2 hover:text-cyan-600 cursor-pointer' />
                <p className='text-sm'> Enhance your design experience by purchasing generative credits through our app. We've simplified the process by setting up a Leonardo account for you. Just buy generative credits, and you're ready to go! This same credits can be also use for our own AI generation features </p>
                <Button onClick={() => navigate(route('dashboard'))} variant="theme" size="xs" className="block">Buy Credits</Button>
            </PopoverContent>
        </Popover>
    </div>
}


export const AiAccountPopover = ({ className }) => {
    const { auth } = usePage().props;
    const { account } = usePromptBuilder(useShallow(state => ({ account: state.account })));

    const { data: accounts } = useQuery({
        queryKey: ['ai-accounts'],
        queryFn: () => fetchAccounts()
    })
    const setPromptBuilder = usePromptBuilder(state => state.set);

    const { data: generativeCredits } = useQuery({
        queryKey: ['ai-credits', account],
        queryFn: () => window.axios.get(route('integration.account-credits', { id: account })).then(({ data }) => data?.api_credits || 0),
        enabled: !!account
    })

    const extraCredits = useMemo(() => auth?.user?.other_credits || 0, [auth?.user]);

    const onAccountChange = useCallback((v) => setPromptBuilder('account', v), [setPromptBuilder]);

    const onConnectAccount = useCallback((type) => {
        ConfirmDialog.fire({
            title: `Connect Your Own ${ucwords(type)} Account`,
            text: "You're about to be directed to the configuration page. Do you want to proceed?",
            confirmButtonText: 'Yes Proceed.',
            allowOutsideClick: false
        }).then((result) => {
            result.isConfirmed && navigate(route('dashboard'));
        })
    }, [])

    return <Popover >
        <PopoverTrigger asChild><Button data-aos="fade-up" className="rounded-xl bg-white group border size-12" variant="icon"><Icon icon="mdi:account-cog" className="group-hover:text-cyan-600  text-3xl" /></Button></PopoverTrigger>
        <PopoverContent className="rounded-2xl flex flex-col gap-4 relative">
            <div className='w-full flex flex-row justify-evenly items-center gap-1'>
                <div className='px-4 py-1 bg-white cursor-pointer border flex flex-col gap-x-2 flex-nowrap items-center rounded-md'
                    data-tooltip-id="rmp-tooltip"
                    data-tooltip-class-name='max-w-[400px]'
                    data-tooltip-content="If using RMP accounts, this refers to the number of credits, where each credits is equivalent to 1 image generation. If using your own account this refer to the number of API tokens/credits your account has."
                >
                    <span className='text-xs font-semibold italic'>Generative Credits</span>
                    <span className='text-md font-semibold'>{generativeCredits}</span>
                </div>
                <div className='px-4 py-1 bg-white cursor-pointer border flex flex-col gap-x-2 flex-nowrap items-center rounded-md'
                    data-tooltip-id="rmp-tooltip"
                    data-tooltip-class-name='max-w-[400px]'
                    data-tooltip-content="Extra credits are useful for features like removing background on images and more"
                >
                    <span className='text-xs font-semibold italic'>Extra Credits</span>
                    <span className='text-md font-semibold'>{extraCredits}</span>
                </div>
            </div>

            <RadioGroup value={account} onValueChange={v => onAccountChange(v)} className="flex flex-col py-2 gap-4">
                {accounts?.map((acc) => <div key={acc.id} className="flex items-center gap-3">
                    <RadioGroupItem value={acc.id} id={`option-${acc.id}`} />
                    <Label htmlFor={`option-${acc.id}`} className="hover:underline cursor-pointer">{acc.display_name}</Label>
                </div>
                )}
            </RadioGroup>

            <Button onClick={() => onConnectAccount('leonardo.ai')} variant="theme-outline" size="xs" className="block">Connect Your Leonardo.Ai Account</Button>

            <Separator />

            <p className='text-xs'> Enhance your design experience by purchasing API credits for Leonardo and Rock My Prompt through our app. We've simplified the process by setting up a Leonardo account for you. Just buy API credits, and you're ready to go! </p>
            <Button onClick={() => navigate(route('dashboard'))} variant="theme" size="xs" className="block">Buy Credits</Button>
        </PopoverContent>
    </Popover>
}
